import { Vue, Component } from "vue-property-decorator";
import { IClientDatatable } from "@/models";
import { ClientDatatable } from "@/components";

@Component({
    components: {
        ClientDatatable,
    },
    metaInfo: {
        title: "Manage clients",
    },
})

export default class ClientsManage extends Vue {

    public loading = false;

    public headers = [
        { text: "", class: "nowrap", value: "snakeAppLogo", sortable: false },
        { text: "Application", class: "nowrap", cellClass: "fixedHeightFirst", value: "snakeAppName", width: 200 },
        { text: "App Registration", class: "nowrap", cellClass: "nowrap", value: "aadClientId", sortable: false },
        { text: "Status", value: "status", class: "nowrap" },
        { text: "Description", value: "description", class: "nowrap", cellClass: "fixedHeight", width: 300, sortable: false },
        { text: "Requested on", value: "request.requestedOn", class: "nowrap", cellClass: "nowrap" },
        { text: "Created on", value: "createdOn", class: "nowrap", cellClass: "nowrap" },
        { text: "", value: "actions", sortable: false },
    ];

    public clients: IClientDatatable[] = [];

    async mounted(): Promise<void> {
        this.loading = true;
        try {
            await this.$store.dispatch("app/loadClients").then(async (data)=>{
                this.clients = data;
                await this.$store.dispatch("app/loadSnakeApps", data.map(r => r.snakeId)).then((snakeApps)=>{
                    this.clients.forEach(ar => {
                        const snakeApp = snakeApps.find(sa => sa.snakeId === ar.snakeId);
                        if(snakeApp){
                            ar.snakeAppName = snakeApp.general.name;
                            ar.snakeAppLogo = snakeApp.general.logo;
                            ar.snakeAppIsPlatform = snakeApp.general.isPlatform;
                        }
                    });
                });
            });
        }
        catch (error) {
            this.$emit("error", error);
        }
        this.loading = false;
    }
}
